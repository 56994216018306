import { useIntl } from "react-intl";
import Link from "next/link";
import FooterData from "../variables/variables.json";
import { useRouter } from "next/router";
import TranslatedMessage from "./TranslatedMessage";
import Image from "next/image";

export function Footer(props) {
	const router = useRouter();
	const intl = useIntl();

	// PROPS VARIABLE
	let propsMiasto = props.miasto;
	let propsPodatek = props.podatek;

	let propsType;
	let extendedUrl = "/";
	let siteCode = router.query.siteCode;

	if (FooterData.miasta[propsMiasto].index) {
		propsType = "/";
	} else if (FooterData.miasta[propsMiasto].witryna) {
		propsType = "/witryna/";
		extendedUrl = "?siteCode=" + siteCode;
	} else {
		propsType = "/" + FooterData.miasta[propsMiasto].name.url + "/";
	}

	const NavigationList = (props) => (
		<nav className="pd-footer__navigation" aria-label="Navigacja w stopce">
			<ul
				className={`pd-footer__navigation__list ${props.class}`}
				aria-label="functions"
				role="menu"
			>
				{props.children}
			</ul>
		</nav>
	);

	const NavigationListItem = (props) => (
		<li className={`pd-footer__navigation__list__item ${props.class}`} role="menuitem">
			<Link
				href={props.href}
				className="pd-footer__navigation__list__item__link"
				rel={props.rel}
				title={props.altTitle}
			>
				{props.children}
			</Link>
		</li>
	);

	return (
		<>
			<footer className="pd-footer pd-section">
				<div className="pd-section__inner-wrap">
					<div className="row">
						<div className="col-xs-12 col-md hide-tablet">
							<NavigationList class={FooterData.footer.row.one.column.one.class}>
								{FooterData.footer.row.one.column.one.list.map((list, index) => (
									<NavigationListItem
										href={list.urlOut ? list.url : propsType + list.url + extendedUrl}
										rel={list.rel}
										title={list.altTitle}
										key={index}
									>
										{list.image && (
											<>
												<div className="pd-footer__navigation__list__item__image--normal">
													<Image
														className={`pd-footer__navigation__list__item__image ${list.class}`}
														src={list.image}
														alt={list.title}
														width={list.width}
														height={list.height}
														style={{
															maxWidth: "100%",
															height: "auto",
														}}
													/>
												</div>
												<div className="pd-footer__navigation__list__item__image--highcontrast">
													<Image
														className={`pd-footer__navigation__list__item__image ${list.class}`}
														src={list.imageContrast}
														alt={list.title}
														width={list.width}
														height={list.height}
														style={{
															maxWidth: "100%",
															height: "auto",
														}}
													/>
												</div>
											</>
										)}
									</NavigationListItem>
								))}
							</NavigationList>
						</div>

						<div className="col-xs-12 col-md-7">
							<NavigationList class="row">
								{FooterData.miasta[propsMiasto].footer.column.map((column, index) => (
									<li key={index} className="col-xs-12 col-md" role="menuitem">
										<span className="sr-only">
											<TranslatedMessage id={column.title} />
										</span>

										<ul role="menu">
											{column.list.map((list, index) => (
												<NavigationListItem
													href={propsType + list.shortName + extendedUrl}
													key={index}
													rel={list.rel}
													title={list.altTitle}
												>
													<TranslatedMessage id={list.translatedMessage} />
												</NavigationListItem>
											))}
										</ul>
									</li>
								))}
							</NavigationList>
						</div>

						<div className="col-xs-12 col-md hide-tablet">
							<NavigationList
								class={FooterData.miasta[propsMiasto].footer.smallFooter.row.one.column.two.class}
							>
								{FooterData.miasta[propsMiasto].footer.smallFooter.row.one.column.two.list.map(
									(list, index) => (
										<NavigationListItem
											href={list.urlOut ? list.url : propsType + list.url + extendedUrl}
											key={index}
											rel={list.rel}
											title={list.altTitle}
										>
											{list.image && (
												<>
													<div className="pd-footer__navigation__list__item__image--normal">
														<Image
															className={`pd-footer__navigation__list__item__image ${list.class}`}
															src={list.image}
															alt={list.title}
															width={list.width}
															height={list.height}
															style={{
																maxWidth: "100%",
																height: "auto",
															}}
														/>
													</div>
													<div className="pd-footer__navigation__list__item__image--highcontrast">
														<Image
															className={`pd-footer__navigation__list__item__image ${list.class}`}
															src={list.imageContrast}
															alt={list.title}
															width={list.width}
															height={list.height}
															style={{
																maxWidth: "100%",
																height: "auto",
															}}
														/>
													</div>
												</>
											)}
										</NavigationListItem>
									),
								)}
							</NavigationList>
						</div>
					</div>

					<div className="row">
						{FooterData.miasta[propsMiasto].footer.smallFooter.row.two.column.map((item, index) => (
							<div className={"col-xs-12 col-md " + (item.class ? item.class : "")} key={index}>
								<NavigationList class={item.class}>
									{item.list.map((list, index) => (
										<NavigationListItem
											href={list.urlOut ? list.url : propsType + list.url + extendedUrl}
											key={index}
											class={list.class}
											rel={list.rel}
											title={list.altTitle}
										>
											{!list.image & (list.title === "footer.providedBy") ? (
												<TranslatedMessage id={"miasta." + propsMiasto + ".footer.providedBy"} />
											) : (
												!list.image && <TranslatedMessage id={list.title} />
											)}

											{list.image && (
												<div className="pd-footer__navigation__list__item__image--normal">
													<Image
														className={`pd-footer__navigation__list__item__image ${list.class}`}
														src={list.image}
														alt={list.title}
														width={list.width}
														height={list.height}
														style={{
															maxWidth: "100%",
															height: "auto",
														}}
													/>
												</div>
											)}
										</NavigationListItem>
									))}
								</NavigationList>
							</div>
						))}
					</div>
				</div>
			</footer>
		</>
	);
}

export default Footer;
