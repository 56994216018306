import JSONData from "../variables/variables.json";
import classNames from "classname";
import Image from "next/image";
import Link from "next/link";
import TranslatedMessage from "./TranslatedMessage";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

import LinkTo from "./mdx/LinkTo";

const Slider = (props) => {
	// PROPS VARIABLE
	let propsMiasto = props.miasto;
	let propsPodatek = props.podatek;
	let propsTypeHero = props.slider;
	let propsIndex = props.index;

	let slider = "slider";

	let miastoVariable = "miasta." + propsMiasto;

	// SLIDER HEADER
	let headerTitle = miastoVariable + "." + slider + "." + [propsPodatek] + ".header.title";

	let headerSubtitle = miastoVariable + "." + slider + "." + [propsPodatek] + ".header.subtitle";

	// SLIDER BUTTON
	let firstButton =
		miastoVariable + "." + slider + "." + [propsPodatek] + ".action.firstButton.title";

	let secondButton =
		miastoVariable + "." + slider + "." + [propsPodatek] + ".action.secondButton.title";

	// JSON VARIABLE
	let miastoJSON = JSONData.miasta[propsMiasto];

	let witryna = miastoJSON.witryna;

	let heroImg = miastoJSON.slider[propsPodatek].header.images;
	let dataMicroModalTrigger = miastoJSON.slider[propsPodatek].name.shortName;

	// BLOG
	let blog = props.blog;
	let title = props.title;
	let description = props.description;
	let imageDesktop = props.imageDesktop;
	let imageTablet = props.imageTablet;
	let imageMobile = props.imageMobile;
	let blogWithAction = props.blogWithAction;
	let blogActionText = props.blogActionText;
	let blogActionUrl = props.blogActionUrl;
	let blogMiasto = props.blogMiasto;
	let blogPodatek = props.blogPodatek;
	let blogButton;
	let blogDataMicroModalTrigger;

	if (blogWithAction == "dialog") {
		blogButton = miastoVariable + "." + slider + "." + [blogPodatek] + ".action.firstButton.title";
		blogDataMicroModalTrigger = JSONData.miasta[blogMiasto].slider[blogPodatek].name.shortName;
	}

	const Hero = (props) => (
		<section
			className={classNames("pd-hero", {
				small: props.small,
				slider: props.slider,
			})}
		>
			{props.children}
		</section>
	);

	const HeroTop = (props) => (
		<div className="pd-hero__top">
			<div className="pd-hero__header">{props.children}</div>
		</div>
	);

	const HeroTitle = (props) => (
		<h1 className="pd-hero__header__title" id={props.id}>
			{props.children}
		</h1>
	);

	const HeroSubtitle = (props) => <p className="pd-hero__header__subtitle">{props.children}</p>;

	const HeroImage = (props) => <div className="pd-hero__image">{props.children}</div>;

	const HeroImageDesktop = (props) => (
		<div className="pd-hero__image__img desktop">{props.children}</div>
	);
	const HeroImageTablet = (props) => (
		<div className="pd-hero__image__img tablet">{props.children}</div>
	);
	const HeroImageMobile = (props) => (
		<div className="pd-hero__image__img mobile">{props.children}</div>
	);

	if (blog && blogWithAction) {
		propsPodatek = blogPodatek;
	}

	// LINK
	let startUrl;
	let startUrlProd = "https://przyjazne-deklaracje.pl/";
	let startUrlTest = "https://test.przyjazne-deklaracje.pl/";

	if (JSONData.miasta[propsMiasto].test) {
		startUrl = startUrlTest;
	} else {
		startUrl = startUrlProd;
	}

	let profileCode = "O4B";
	let templateCode = miastoJSON.slider[propsPodatek].templateCode;
	let templateScope = JSONData.miasta[propsMiasto].templateScope;
	let templateScopeFull = "&templateScope=" + templateScope;

	if (miastoJSON.index) {
		templateScopeFull = "";
	}

	/* LINK TO */
	let linkTo =
		startUrl +
		"kalkulatory/menadzer/menadzer-kalkulatorow?profileCode=" +
		profileCode +
		"&templateCode=" +
		templateCode +
		templateScopeFull;

	return (
		<>
			<Hero small={props.small} slider={props.slider}>
				<HeroTop>
					<HeroTitle>{!blog ? <TranslatedMessage id={headerTitle} /> : title}</HeroTitle>
					<HeroSubtitle>
						{!blog ? <TranslatedMessage id={headerSubtitle} /> : description}
					</HeroSubtitle>
				</HeroTop>

				{blogWithAction == "dialog" && (
					<div className="pd-hero__action">
						<div
							className={classNames("pd-hero__action__inner", {
								"pd-hero__action--to-left": props.small,
							})}
						>
							{JSONData.miasta[blogMiasto].slider[blogPodatek].action.firstButton.turnModal ? (
								<button
									data-a11y-dialog-show={blogDataMicroModalTrigger}
									className="pd-btn pd-btn--primary"
								>
									{blogActionText}
								</button>
							) : JSONData.miasta[blogMiasto].slider[blogPodatek].action.firstButton.turnUrl ? (
								<Link
									href={JSONData.miasta[blogMiasto].slider[blogPodatek].action.firstButton.url}
									className="pd-btn pd-btn--primary"
								>
									{blogActionText}
								</Link>
							) : (
								<Link href={linkTo} className="pd-btn pd-btn--primary">
									{blogActionText}
								</Link>
							)}
						</div>
					</div>
				)}

				{blogWithAction == "link" && (
					<div className="pd-hero__action">
						<div
							className={classNames("pd-hero__action__inner", {
								"pd-hero__action--to-left": props.small,
							})}
						>
							<Link href={blogActionUrl} className="pd-btn pd-btn--primary">
								{blogActionText}
							</Link>
						</div>
					</div>
				)}

				{!blog && miastoJSON.slider[propsPodatek].action.available && (
					<div className="pd-hero__action">
						<div
							className={classNames("pd-hero__action__inner", {
								"pd-hero__action--two-buttons":
									miastoJSON.slider[propsPodatek].action.secondButton.available,
								"pd-hero__action--two-buttons--no-mobile":
									!miastoJSON.slider[propsPodatek].action.secondButton.availableMobile,
								"pd-hero__action--to-left": props.small,
							})}
						>
							{miastoJSON.slider[propsPodatek].action.firstButton.scrollTo ? (
								<LinkTo
									to="wypelnij-deklaracje"
									href="#wypelnij-deklaracje"
									spy={true}
									smooth={true}
									offset={-70}
									duration={500}
									className="pd-btn pd-btn--primary"
								>
									<TranslatedMessage id={firstButton} />
								</LinkTo>
							) : miastoJSON.slider[propsPodatek].action.firstButton.turnModal ? (
								<button
									data-a11y-dialog-show={dataMicroModalTrigger}
									className="pd-btn pd-btn--primary"
								>
									<TranslatedMessage id={firstButton} />
								</button>
							) : miastoJSON.slider[propsPodatek].action.firstButton.turnUrl ? (
								<Link
									href={miastoJSON.slider[propsPodatek].action.firstButton.url}
									className="pd-btn pd-btn--primary"
								>
									<TranslatedMessage id={firstButton} />
								</Link>
							) : (
								<Link href={linkTo} className="pd-btn pd-btn--primary">
									<TranslatedMessage id={firstButton} />
								</Link>
							)}

							{miastoJSON.slider[propsPodatek].action.secondButton.available === true &&
								// <Link
								//   href={miastoJSON.slider[propsPodatek].action.secondButton.url}
								//   className="pd-btn pd-btn--outline read-more">
								//   <TranslatedMessage id={secondButton} />
								// </Link>
								(miastoJSON.slider[propsPodatek].action.secondButton.scrollTo ? (
									<LinkTo
										to="czytaj-wiecej"
										href="#czytaj-wiecej"
										spy={true}
										smooth={true}
										offset={-70}
										duration={500}
										className="pd-btn pd-btn--outline read-more"
									>
										<TranslatedMessage id={secondButton} />
									</LinkTo>
								) : (
									<Link
										href={miastoJSON.slider[propsPodatek].action.secondButton.url}
										className="pd-btn pd-btn--primary"
									>
										<TranslatedMessage id={secondButton} />
									</Link>
								))}
						</div>
					</div>
				)}

				{props.small ? (
					props.blog ? (
						<HeroImage>
							<HeroImageDesktop>
								<Image
									alt=""
									src={imageDesktop}
									quality={100}
									priority
									fill
									sizes="100vw"
									style={{
										objectFit: "contain",
										objectPosition: "right bottom",
									}}
								/>
							</HeroImageDesktop>
							<HeroImageTablet>
								<Image
									alt=""
									src={imageTablet}
									quality={100}
									priority
									fill
									sizes="100vw"
									style={{
										objectFit: "contain",
										objectPosition: "right bottom",
									}}
								/>
							</HeroImageTablet>
							<HeroImageMobile>
								<Image
									alt=""
									src={imageMobile}
									quality={100}
									priority
									fill
									sizes="100vw"
									style={{
										objectFit: "contain",
										objectPosition: "right bottom",
									}}
								/>
							</HeroImageMobile>
						</HeroImage>
					) : (
						<HeroImage>
							<HeroImageDesktop>
								<Image
									alt=""
									src={heroImg.desktop}
									quality={100}
									priority
									fill
									sizes="100vw"
									style={{
										objectFit: "contain",
										objectPosition: "right bottom",
									}}
								/>
							</HeroImageDesktop>
							<HeroImageTablet>
								<Image
									alt=""
									src={heroImg.tablet}
									quality={100}
									priority
									fill
									sizes="100vw"
									style={{
										objectFit: "contain",
										objectPosition: "right bottom",
									}}
								/>
							</HeroImageTablet>
							<HeroImageMobile>
								<Image
									alt=""
									src={heroImg.mobile}
									width="880"
									height="488"
									quality={100}
									priority
									style={{
										width: "100%",
										height: "auto",
									}}
								/>
							</HeroImageMobile>
						</HeroImage>
					)
				) : (
					<HeroImage>
						<HeroImageDesktop>
							<Image
								alt=""
								src={heroImg.desktop}
								width="1920"
								height="488"
								quality={100}
								priority
								style={{
									width: "100%",
									height: "auto",
								}}
							/>
						</HeroImageDesktop>
						<HeroImageTablet>
							<Image
								alt=""
								src={heroImg.tablet}
								width="1920"
								height="488"
								quality={100}
								priority
								style={{
									width: "100%",
									height: "auto",
								}}
							/>
						</HeroImageTablet>
						<HeroImageMobile>
							<Image
								alt=""
								src={heroImg.mobile}
								width="1920"
								height="800"
								quality={100}
								priority
								style={{
									width: "100%",
									height: "auto",
								}}
							/>
						</HeroImageMobile>
					</HeroImage>
				)}
			</Hero>

			{/* <VhScript /> */}
		</>
	);
};

export default Slider;
