import { useRouter } from "next/router";
import { useIntl } from "react-intl";
import { NextSeo } from "next-seo";
import Variables from "../variables/variables.json";

export default function SEO(props) {
	const router = useRouter();
	const intl = useIntl();

	let hostName = "https://przyjazne-deklaracje.pl";
	let routerUrl = router.asPath;
	let routerLocale = router.locale;
	let RouterRedirect = props.routerRedirect;

	let Miasto;
	let Podatek;

	let miastoJSON;
	let witryna;

	let heroImg;
	let heroImgHome;

	if (!props.blog) {
		Miasto = props.miasto;
		Podatek = props.podatek;

		miastoJSON = Variables.miasta[Miasto];
		witryna = miastoJSON.witryna;

		heroImg = miastoJSON.slider[Podatek].header.images;
	}

	let dateISO8601 = props.date + "T" + props.hours + "Z";

	return (
		<>
			{props.blog ? (
				<NextSeo
					title={props.seoTitle}
					description={props.seoDescription}
					canonical={"https://przyjazne-deklaracje.pl" + routerUrl}
					openGraph={{
						site_name: "Przyjazne Deklaracje",
						type: "website",
						locale: routerLocale,
						// url: witryna ? hostName + RouterRedirect : hostName + routerUrl,
						title: props.seoTitle,
						description: props.seoDescription,
						images: [
							{
								url: props.imagesUrl,
							},
						],
						type: "article",
						article: {
							authors: [props.author],
							publishedTime: dateISO8601,
						},
					}}
					noindex={false}
					nofollow={false}
				/>
			) : (
				<NextSeo
					title={intl.formatMessage({
						id: "miasta." + Miasto + ".SEO." + Podatek + ".title",
					})}
					description={intl.formatMessage({
						id: "miasta." + Miasto + ".SEO." + Podatek + ".description",
					})}
					canonical={"https://przyjazne-deklaracje.pl" + routerUrl}
					openGraph={{
						site_name: intl.formatMessage({
							id: "miasta." + Miasto + ".siteName",
						}),
						type: "website",
						locale: routerLocale,
						url: witryna ? hostName + RouterRedirect : hostName + routerUrl,
						title: intl.formatMessage({
							id: "miasta." + Miasto + ".SEO." + Podatek + ".title",
						}),
						description: intl.formatMessage({
							id: "miasta." + Miasto + ".SEO." + Podatek + ".description",
						}),
						images: [
							{
								url: heroImg.mobile,
							},
						],
					}}
					noindex={Variables.miasta[Miasto].test}
					nofollow={Variables.miasta[Miasto].test}
				/>
			)}
		</>
	);
}
