import { HighContrast } from "../hook/HighContrast";
import { FontButton } from "../hook/FontButton";
import Image from "next/image";

const WCAG = () => {
	const [theme, toggleTheme] = HighContrast();
	const [fontSize, fontSizeFixed, fontSizeReset, fontSizeIncrease, fontSizeDecrease] = FontButton();

	const WCAGButton = (props) => (
		<li className="pd-navigation__menu-item" role="menuitem" tabIndex="-1" aria-expanded="false">
			<div className="wcag-contrast-button__container">
				<button onClick={toggleTheme} aria-label="Wysoki kontrast" className="wcag-contrast-button">
					<span className="desktop">
						<Image
							alt=""
							src="https://przyjazne-deklaracje.pl/resources/images/icons/general/wcag/highcontrast-desktop.svg"
							width={22}
							height={27}
						/>
					</span>
					<span className="contrast">
						<Image
							alt=""
							src="https://przyjazne-deklaracje.pl/resources/images/icons/general/wcag/highcontrast-contrast-v2.svg"
							width={22}
							height={27}
						/>
					</span>
					<span className="mobile">
						<Image
							alt=""
							src="https://przyjazne-deklaracje.pl/resources/images/icons/general/wcag/highcontrast-mobile.svg"
							width={22}
							height={27}
						/>
					</span>
				</button>
			</div>
		</li>
	);

	const WCAGFont = ({ children, click, label, font, id }) => (
		<li
			className="pd-navigation__menu-item fontSizeReset"
			role="menuitem"
			tabIndex="-1"
			aria-expanded="false"
		>
			<button
				onClick={click}
				aria-label={label}
				id={id}
				className={"wcag-font-button " + "wcag-font-" + font}
			>
				<span className="wcag-font-button__letter">{children}</span>
			</button>
		</li>
	);

	return (
		<ul
			className="pd-navigation__menu pd-navigation__menu__wcag"
			role="menubar"
			aria-label="functions"
			id="appmenu"
		>
			<WCAGButton />
			<WCAGFont click={fontSizeDecrease} font="14" label="Zmniejsz rozmiar czcionki">
				A-
			</WCAGFont>
			<WCAGFont click={fontSizeReset} font="16" label="Zresetuj rozmiar czcionki">
				<span className="wcag-font-button__letter__reset">
					<span className="pd-desktop">
						<Image
							alt=""
							src="https://przyjazne-deklaracje.pl/resources/images/icons/general/wcag/reset-black.svg"
							width={22}
							height={26}
						/>
					</span>
					<span className="pd-contrast">
						<Image
							alt=""
							src="https://przyjazne-deklaracje.pl/resources/images/icons/general/wcag/reset-highcontrast.svg"
							width={22}
							height={26}
						/>
					</span>
					<span className="pd-mobile">
						<Image
							alt=""
							src="https://przyjazne-deklaracje.pl/resources/images/icons/general/wcag/reset-white.svg"
							width={22}
							height={26}
						/>
					</span>
				</span>
			</WCAGFont>
			<WCAGFont click={fontSizeIncrease} font="20" label="Zwiększ rozmiar czionki">
				A+
			</WCAGFont>

			{/* <div>{fontSizeFixed}</div> */}
		</ul>
	);
};

export default WCAG;
